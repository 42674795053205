const production = {
  url: {
    API_URL: `https://server.vetygo.ca/`
  }
};


const dev = {
  url: {
    API_URL: `https://server.marengoapp.ca/`
  }
};

export const config = process.env.NODE_ENV === 'production' ? production : dev;
