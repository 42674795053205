import {BrowserRouter, Route, Routes} from "react-router-dom";
import "./App.css";
import HomePage from "./pages/Home";
import DownloadPage from "./pages/Download";
import NoTagPage from "./pages/NoTag";
import RedirectPage from "./pages/RedirectPage";
import PetPage from "./pages/PetPage";
import TabIdGeneratorMiddlewarePage from "./pages/TabIdGeneratorMiddleware";
import TagPage from "./pages/Tag";
import DownloadVetygoPage from "./pages/DownloadVetygo";

//Router
function App() {
    return (
        <div className="App">
            <BrowserRouter>
                <Routes>
                    <Route path="/tag/:tagCode" element={<TagPage/>}/>
                    <Route path="/pet/:petId" element={<PetPage/>}/>
                    <Route path="/:tagCode" element={<TabIdGeneratorMiddlewarePage/>}/>
                    <Route path="/notag" element={<NoTagPage/>}/>
                    <Route path="/home" element={<HomePage/>}/>
                    <Route path="/microchip/:microchipId" element={<HomePage/>}/>
                    <Route path="/download" element={<DownloadPage/>}/>
                    <Route path="/downloadVetygo" element={<DownloadVetygoPage/>}/>
                    <Route path="/" element={<RedirectPage/>}/>
                </Routes>
            </BrowserRouter>

        </div>
    );
}

export default App;
