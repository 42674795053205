import {faCaretDown, faMars, faVenus} from '@fortawesome/free-solid-svg-icons';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {Accordion, AccordionDetails, AccordionSummary, Grid, Paper, Typography} from '@mui/material';
import React, {useEffect, useState} from 'react';
import {useTranslation} from 'react-i18next';

interface PetExtraInfoState {
    petDateOfBirth: Date;
    petSex: string;
    petWeightInPounds: number;
}

const PetExtraInfoCard: React.FunctionComponent<PetExtraInfoState> = (props) => {
    const {t} = useTranslation();
    let [petAge, setPetAge] = useState<number>(0);
    let [petAgeText, setPetAgeText] = useState<string>('');

    useEffect(() => {
        calculateAgeInfo();
    });

    const calculateAgeInfo = () => {
        let timeDiff = Math.abs(Date.now() - props.petDateOfBirth.getTime());
        let ageInDays = Math.floor(timeDiff / (1000 * 3600 * 24));

        let months = Math.floor(ageInDays / 30);
        let years = Math.floor(ageInDays / 365);

        if (years > 0) {
            setPetAge(years);
            setPetAgeText(t('years_old'));
        } else if (months > 0) {
            setPetAge(months);
            setPetAgeText(t('months_old'));
        } else {
            setPetAge(ageInDays);
            setPetAgeText(t('days_old'));
        }
    };

    return (
        <Paper elevation={4} style={{
            marginRight: '1rem',
            marginLeft: '1rem',
            marginBottom: '1rem',
            borderRadius: '20px',
        }}>
            <Accordion
                elevation={0}
                style={{
                    borderRadius: '20px',
                }}
            >
                <AccordionSummary expandIcon={<FontAwesomeIcon icon={faCaretDown}/>}>
                    <Typography variant="h6">{t('additional_info')}</Typography>
                </AccordionSummary>
                <AccordionDetails style={{paddingTop: '0rem'}}>
                    <Grid container columns={{xs: 3, sm: 9, md: 12}}>
                        <Grid padding={"0rem"} item={true} xs={1} sm={3} md={4}>
                            <Typography fontSize={"2rem"}>{petAge}</Typography>
                        </Grid>
                        <Grid padding={"0rem"} item={true} xs={1} sm={3} md={4}>
                            <Typography fontSize={"2rem"}>{props.petWeightInPounds.toFixed(2)}</Typography>
                        </Grid>
                        <Grid item={true} xs={1} sm={3} md={4}>
                            {
                                props.petSex === "Female" ? (
                                    <FontAwesomeIcon icon={faVenus} fontSize={"2.2rem"}/>) : (
                                    <FontAwesomeIcon icon={faMars} fontSize={"2.2rem"}/>
                                )
                            }
                        </Grid>
                        <Grid padding={"0rem"} item={true} xs={1} sm={3} md={4}>
                            <Typography fontWeight={"bold"}>{petAgeText}</Typography>
                        </Grid>
                        <Grid padding={"0rem"} item={true} xs={1} sm={3} md={4}>
                            <Typography fontWeight={"bold"}>Lbs</Typography>
                        </Grid>
                        <Grid padding={"0rem"} item={true} xs={1} sm={3} md={4}>
                            <Typography
                                fontWeight={"bold"}>{props.petSex === "Male" ? (t('male')) : (t('female'))}</Typography>
                        </Grid>
                    </Grid>
                </AccordionDetails>
            </Accordion>
        </Paper>
    )
}


export default PetExtraInfoCard;
