import React, { useEffect } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';

export interface IRedirectPageProps {}

const RedirectPage: React.FunctionComponent<IRedirectPageProps> = () => {
    let { pathname } = useLocation();
    const navigate = useNavigate();
    let tagCode = pathname.substring(pathname.lastIndexOf('/') + 1);

    useEffect(() => {
        if(tagCode === "" || tagCode == null){
            navigate(`/home`);
        } else {
            const generateUniqueId: number = Math.floor(Math.random() * 1000000);
            navigate(`/tag/${tagCode}?tabId=${generateUniqueId}`);
        }
    }, []);
    return (
        <div></div>
    );
};

export default RedirectPage;