import {faLeaf} from '@fortawesome/free-solid-svg-icons';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {Card, CardContent, CardHeader, Paper, Typography} from '@mui/material';
import React from 'react';
import {useTranslation} from 'react-i18next';

interface PetInfoState {
    petName: string;
    petSpecies: string;
    petDescription: string;
    petIsOutdoor: boolean;
    petIsLost: boolean;
}

const PetInfoCard: React.FunctionComponent<PetInfoState> = (props) => {
    const {t} = useTranslation();

    return (
        <Paper elevation={5} style={{
            marginRight: '1rem',
            marginLeft: '1rem',
            marginTop: '20px',
            borderRadius: '20px',
            position: 'relative',
        }}>
            <Card variant="outlined"
                  style={{
                      border: props.petIsLost ? "0.3rem solid red" : "",
                      borderRadius: '20px',
                  }}>
                <CardHeader
                    title={<Typography sx={{fontSize: '1.4rem', fontWeight: 'bold'}} >
                        {props.petName}
                    </Typography>}
                    subheader={props.petSpecies === "Cat" ? (t('cat')) : (t('dog'))}
                    action={props.petIsOutdoor ?
                        <FontAwesomeIcon icon={faLeaf} color="#2a9728" fontSize={"2.2rem"}/> : null}
                    style={{textAlign: 'left', paddingBottom: '0rem'}}
                />
                <CardContent style={{paddingTop: '0rem'}}>
                    <Typography style={{textAlign: 'left'}} sx={{fontSize: '1.2rem', fontWeight: 'bold'}} gutterBottom>
                        {t("description_bio")}
                    </Typography>
                    <Typography style={{textAlign: 'left'}} sx={{fontSize: '1rem', color: "#8B8B8B"}} gutterBottom>
                        {props.petDescription}
                    </Typography>
                </CardContent>
            </Card>
        </Paper>
    )
}


export default PetInfoCard;
