import { Card, CardContent, Grid, Paper, Skeleton } from '@mui/material';
import React from 'react';

interface PetInfoStateSkeleton {
}

const PetInfoCardSkeleton: React.FunctionComponent<PetInfoStateSkeleton> = (props) => {

	return (
		<Paper elevation={2}>
			<Card variant="outlined">
				<Skeleton />
				<CardContent>
					<Grid container spacing={{ xs: 2, md: 3 }} columns={{ xs: 3, sm: 9, md: 12 }}>
						<Grid item={true} xs={1} sm={3} md={4}>
						<Skeleton />
						</Grid>
						<Grid item={true} xs={1} sm={3} md={4}>
						<Skeleton />
						</Grid>
						<Grid item={true} xs={1} sm={3} md={4}>
						<Skeleton />
						</Grid>
					</Grid>
					<Skeleton />
					<Skeleton />
				</CardContent>
			</Card>
		</Paper>
	)
}


export default PetInfoCardSkeleton;
