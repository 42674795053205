import { Card, CardContent, Grid, Paper, Skeleton } from '@mui/material';
import React from 'react';

interface ContactInfoStateSkeleton {
}

const ContactInfoCardSkeleton: React.FunctionComponent<ContactInfoStateSkeleton> = (props) => {

    return (
        <Paper elevation={2}>
            <Card variant="outlined">
                <Skeleton />
                <CardContent>
                    <Grid justifyContent="center" alignItems="center" container spacing={{ xs: 2, md: 3 }} columns={{ xs: 24, sm: 24, md: 24 }}>
                        <Grid item={true} xs={5} sm={5} md={5}>
                            <Skeleton variant="circular" />
                        </Grid>
                        <Grid style={{ textAlign: 'left' }} item={true} xs={10} sm={10} md={10}>
                            <Skeleton height="3rem"/>
                        </Grid>
                        <Grid item={true} xs={3} sm={3} md={3}>
                            <Skeleton variant="circular" />
                        </Grid>
                        <Grid item={true} xs={3} sm={3} md={3}>
                            <Skeleton variant="circular" />
                        </Grid>
                    </Grid>
                </CardContent>
            </Card>
        </Paper>
    )
}


export default ContactInfoCardSkeleton;
