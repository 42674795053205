import React, { useEffect } from 'react';
import {useLocation, useNavigate} from "react-router-dom";

export interface ITabIdGeneratorMiddlewareProps { }

const TabIdGeneratorMiddlewarePage: React.FunctionComponent<ITabIdGeneratorMiddlewareProps> = () => {
    let { pathname } = useLocation();
    const navigate = useNavigate();
    let tagCode = pathname.substring(pathname.lastIndexOf('/') + 1);

    useEffect(() => {
        const generateUniqueId: number = Math.floor(Math.random() * 1000000);

        navigate(`/tag/${tagCode}?tabId=${generateUniqueId}`);
    }, []);

    return (
        <div>
        </div>
    );
};

export default TabIdGeneratorMiddlewarePage;
