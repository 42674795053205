import {Button, Typography,} from '@mui/material';
import React, {useEffect} from 'react';
import {useTranslation} from 'react-i18next';
import {isAndroid, isIOS} from "react-device-detect";

export interface IDownloadPageProps { }

const DownloadVetygoPage: React.FunctionComponent<IDownloadPageProps> = () => {
    const { t } = useTranslation();

    
    useEffect(() => {
        if (isAndroid) {
          window.location.href =
            "https://play.google.com/store/apps/details?id=com.marengo.marengo";
        }else if(isIOS) {
          window.location.href =
            "https://apps.apple.com/app/id1668893226";
        } else{
          window.location.href =
            "https://vetygo.ca/";
        }
      }, []);

    return (
        <div>
            <Typography>{t("download_page_text")}</Typography>
            <Button
                variant="contained"
                style={{
                    padding: "1rem",
                    borderRadius: 10,
                    marginBottom: "1rem"
                }}
                onClick={() => window.location.href = "https://vetygo.ca/"}>
                {t("download_app")}
            </Button>
        </div>
    );
};

export default DownloadVetygoPage;
