import { Button, Typography } from '@mui/material';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';

export interface INoTagPageProps {}

const NoTagPage: React.FunctionComponent<INoTagPageProps> = () => {
    const { t } = useTranslation();
    const navigate = useNavigate();

    return (
        <div>
            <Typography padding={"1rem"}>{t('no_tag_page_title')}</Typography>
            <Button
                variant="contained"
                style={{
                    padding: "1rem",
                    borderRadius: 10,
                    marginBottom: "1rem"
                }}
                onClick={() => navigate("/")}>
                {t("go_back")}
            </Button>
        </div>
    );
};

export default NoTagPage;