import axios from 'axios';
import React, {useEffect, useState} from 'react';
import {useLocation, useNavigate} from 'react-router-dom';
import {config} from '../Constant';
import PetInfoCard from '../components/PetInfoCard';
import PetInfoCardSkeleton from '../components/PetInfoCardSkeleton';
import ContactInfoCard from '../components/ContactInfoCard';
import ContactInfoCardSkeleton from '../components/ContactInfoCardSkeleton';
import PetPicturesCaroussel from "../components/PetPicturesCaroussel";
import {Button, Typography} from "@mui/material";
import {useTranslation} from "react-i18next";
import PetExtraInfoCard from "../components/PetExtraInfoCard";
import MarengoCopyright from "../components/MarengoCopyright";

const API_URL = config.url.API_URL;

export interface ITagPageProps {
}

const PetPage: React.FunctionComponent<ITagPageProps> = () => {
    let {pathname} = useLocation();
    const { t } = useTranslation();
    const navigate = useNavigate();

    let [petName, setPetName] = useState<string>('');
    let [petSex, setPetSex] = useState<string>('');
    let [petDateOfBirth, setPetDateOfBirth] = useState<Date>(new Date());
    let [petSpecies, setPetSpecies] = useState<string>('');
    let [petWeightInPounds, setPetWeightInPounds] = useState<number>(0);
    let [petDescription, setPetDescription] = useState<string>('');
    let [petIsOutdoor, setPetIsOutdoor] = useState<boolean>(false);
    let [petIsLost, setPetIsLost] = useState<boolean>(false);

    let [contactList, setContactList] = useState<any[]>([]);
    let [noPetWithThatShareableId, setNoPetWithThatShareableId] = useState<boolean>(false);

    let [petPicturesList, setPetPicturesList] = useState<string[]>([]);
    let petShareableId = pathname.substring(pathname.lastIndexOf('/') + 1);


    useEffect(() => {
        fetchContactInfo();
        fetchPetInfo();
    }, []);

    const fetchContactInfo = () => {
        axios.get(`${API_URL}getContactFromPetShareableId/${petShareableId}`)
            .then(function (response) {
                if (response.status === 200) {
                    setContactList(response.data.owners)
                } else {
                    setNoPetWithThatShareableId(true)
                }
            })
            .catch(function (error) {
                setNoPetWithThatShareableId(true)
            })
    };

    const fetchPetInfo = () => {
        axios.get(`${API_URL}getPetInfoFromPetShareableId/${petShareableId}`)
            .then(function (response) {
                setPetName(response.data.pet.Name);
                setPetSex(response.data.pet.Sex);
                setPetSpecies(response.data.pet.Species);
                setPetWeightInPounds(response.data.pet.Weight * 2.205);
                setPetDescription(response.data.pet.Notes);
                setPetIsOutdoor(response.data.pet.isOutdoor);
                setPetIsLost(response.data.pet.isLost);

                setPetPicturesList(response.data.petPicturesUrl);
                setPetDateOfBirth(new Date(response.data.pet.DOB));
            })
            .catch(function (error) {
            });
    };

    return (
        <div>
            {noPetWithThatShareableId ? (
                <div>
                    <Typography padding={"1rem"}>{t('no_pet_page_title')}</Typography>
                    <Button
                        variant="contained"
                        style={{
                            padding: "1rem",
                            borderRadius: 10,
                            marginBottom: "1rem"
                        }}
                        onClick={() => navigate("/")}>
                        {t("go_back")}
                    </Button>
                </div>
            ) : (
                <div style={{paddingBottom: '2rem'}}>
                    <PetPicturesCaroussel petIsLost={petIsLost} petPicturesList={petPicturesList} />
                    {
                        petName ? (
                            <PetInfoCard petName={petName}
                                         petSpecies={petSpecies}
                                         petDescription={petDescription}
                                         petIsOutdoor={petIsOutdoor}
                                         petIsLost={petIsLost}/>
                        ) : (
                            <PetInfoCardSkeleton/>
                        )
                    }
                    {
                        contactList && contactList.length > 0 ? (
                            <ContactInfoCard contactList={contactList}/>
                        ) : (
                            <ContactInfoCardSkeleton/>
                        )
                    }
                    {
                        petName ? (
                            <PetExtraInfoCard petDateOfBirth={petDateOfBirth}
                                              petSex={petSex}
                                              petWeightInPounds={petWeightInPounds}/>
                        ) : (
                            <PetInfoCardSkeleton/>
                        )
                    }
                </div>)}
            <MarengoCopyright />
        </div>
    );
};

export default PetPage;
