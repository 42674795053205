import {faCircleCheck, faLeaf} from '@fortawesome/free-solid-svg-icons';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {Card, CardContent, CardHeader, Paper, Typography} from '@mui/material';
import React from 'react';
import {useTranslation} from 'react-i18next';

interface PetInfoMicrochipState {
    petName: string;
    petSpecies: string;
    petBreed: string;
    petDescription: string;
    petIsOutdoor: boolean;
    petIsLost: boolean;
    petMicrochipNumber: string;
    petIsVerified: boolean;
}

const PetInfoCardMicrochip: React.FunctionComponent<PetInfoMicrochipState> = (props) => {
    const {t} = useTranslation();

    return (
        <Paper elevation={5} style={{
            margin: '1rem',
            borderRadius: '20px',
            position: 'relative',
        }}>
            <Card variant="outlined"
                  style={{
                      border: props.petIsLost ? "0.3rem solid red" : "",
                      borderRadius: '20px',
                  }}>
                <CardHeader
                    title={<Typography sx={{fontSize: '2rem', fontWeight: 'bold'}}>
                        {props.petName}
                        {"\t"}
                        {props.petIsVerified ? <FontAwesomeIcon color={"#404EED"} icon={faCircleCheck} fontSize={"2rem"}/> : <div/>}
                    </Typography>}
                    action={props.petIsOutdoor ?
                        <FontAwesomeIcon icon={faLeaf} color="#2a9728" fontSize={"2.2rem"}/> : null}
                    style={{textAlign: 'left', paddingBottom: '0rem'}}
                />
                <CardContent style={{paddingTop: '0rem'}}>
                    <Typography style={{textAlign: 'left'}} sx={{fontSize: '1.2rem', fontWeight: 'bold'}} gutterBottom>
                        {t("breed")}{": "}
                        <span style={{fontWeight: 'normal', color: "#8B8B8B"}}>
                            {props.petSpecies === "Cat" ? (t('cat')) : (t('dog'))}{", "}
                            {props.petBreed.charAt(0).toUpperCase() + props.petBreed.slice(1)}
                        </span>
                    </Typography>
                    <Typography style={{textAlign: 'left'}} sx={{fontSize: '1.2rem', fontWeight: 'bold'}} gutterBottom>
                        {t("microchip")}{": "}
                        <span style={{fontWeight: 'normal', color: "#8B8B8B"}}>
                            {props.petMicrochipNumber}
                        </span>
                    </Typography>
                    <Typography style={{textAlign: 'left'}} sx={{fontSize: '1.2rem', fontWeight: 'bold'}} gutterBottom>
                        {t("description_bio")}{": "}
                        <span style={{fontWeight: 'normal', color: "#8B8B8B"}}>
                            {props.petDescription}
                        </span>
                    </Typography>
                </CardContent>
            </Card>
        </Paper>
    )
}


export default PetInfoCardMicrochip;
