import {Box, Button, MobileStepper, Paper, Skeleton,} from '@mui/material';
import React from 'react';
import {useTranslation} from 'react-i18next';
import SwipeableViews from "react-swipeable-views";

interface PetPicturesCarousselState {
    petIsLost: boolean;
    petPicturesList: string[];
}

const PetPicturesCaroussel: React.FunctionComponent<PetPicturesCarousselState> = (props) => {
    const {t} = useTranslation();
    const [activeStep, setActiveStep] = React.useState(0);

    const handleNext = () => {
        setActiveStep((prevActiveStep) => prevActiveStep + 1);
    };

    const handleBack = () => {
        setActiveStep((prevActiveStep) => prevActiveStep - 1);
    };

    const handleStepChange = (step: number) => {
        setActiveStep(step);
    };

    return (
        <Paper
            elevation={5}>
            {
                props.petPicturesList && props.petPicturesList.length > 0 ? (
                    <div>
                        <SwipeableViews
                            axis='x'
                            index={activeStep}
                            onChangeIndex={handleStepChange}
                            enableMouseEvents
                        >
                            {props.petPicturesList.map((url, index) => (
                                <div key={url}>
                                    <Box
                                        component="img"
                                        justifyContent="center"
                                        sx={{
                                            height: 'auto',
                                            maxHeight: '30rem',
                                            maxWidth: '30rem',
                                            overflow: 'hidden',
                                            width: '100%',
                                        }}
                                        src={url}
                                        alt={"No pictures"}
                                    />
                                </div>
                            ))}
                        </SwipeableViews>
                        {props.petIsLost ? (
                            <div
                                style={{
                                    position: 'absolute',
                                    top: '15px', // Adjust the top position as needed
                                    right: '15px', // Adjust the right position as needed
                                    color: 'red',
                                    padding: '5px',
                                    border: '5px solid red',
                                    background: 'rgba(255, 0, 0, 0.2)', // Set the background to transparent
                                    backdropFilter: 'blur(10px)',
                                    fontSize: '2.5rem', // Set the font size to make the text bigger
                                    fontWeight: 'bold',
                                    borderRadius: '20px',
                                }}
                            >
                                {t("lost")}
                            </div>
                        ) : <div/>}
                        <MobileStepper
                            steps={props.petPicturesList.length}
                            style={{
                                width: '95%',
                                height: '0px',
                                padding: '0px',
                                marginTop: '-75px',
                                position: 'relative',
                                backgroundColor: 'rgba(255, 255, 255, 0.0)',
                            }}
                            position="static"
                            activeStep={activeStep}
                            nextButton={
                                <Button
                                    size="small"
                                    onClick={handleNext}
                                    disabled={activeStep === props.petPicturesList.length - 1}
                                >
                                </Button>
                            }
                            backButton={
                                <Button size="small" onClick={handleBack} disabled={activeStep === 0}>
                                </Button>
                            }
                            sx={{
                                '.MuiMobileStepper-dot': {
                                    backgroundColor: 'white',
                                    margin: '0 4px',
                                    width: '13px',
                                    height: '13px',
                                },
                                '.MuiMobileStepper-dotActive': {
                                    backgroundColor: "#404EED",
                                    margin: '0 4px',
                                    width: '13px',
                                    height: '13px',
                                },
                                justifyContent: 'center',
                            }}
                        />
                    </div>
                ) : (
                    <Skeleton height="30rem"/>
                )
            }
        </Paper>
    )
}


export default PetPicturesCaroussel;
