import {Box, Button, MobileStepper, Skeleton,} from '@mui/material';
import React from 'react';
import SwipeableViews from "react-swipeable-views";
import {faCircleArrowLeft, faCircleArrowRight} from "@fortawesome/free-solid-svg-icons";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";

interface PetPicturesCarousselState {
    petPicturesList: string[];
}

const PetPicturesCarousselMicrochip: React.FunctionComponent<PetPicturesCarousselState> = (props) => {
    const [activeStep, setActiveStep] = React.useState(0);

    const handleNext = () => {
        setActiveStep((prevActiveStep) => prevActiveStep + 1);
    };

    const handleBack = () => {
        setActiveStep((prevActiveStep) => prevActiveStep - 1);
    };

    const handleStepChange = (step: number) => {
        setActiveStep(step);
    };

    return (
        <div>
            {
                props.petPicturesList && props.petPicturesList.length > 0 ? (
                    <div>
                        <SwipeableViews
                            axis='x'
                            index={activeStep}
                            onChangeIndex={handleStepChange}
                            enableMouseEvents
                        >
                            {props.petPicturesList.map((url, index) => (
                                <div key={url}>
                                    <Box
                                        component="img"
                                        justifyContent="center"
                                        sx={{
                                            borderRadius: '20px',
                                            height: 'auto',
                                            maxHeight: '30rem',
                                            maxWidth: '30rem',
                                            overflow: 'hidden',
                                            width: '100%',
                                        }}
                                        src={url}
                                        alt={"No pictures"}
                                    />
                                </div>
                            ))}
                        </SwipeableViews>
                        <MobileStepper
                            steps={props.petPicturesList.length}
                            style={{
                                marginTop: '-75px',
                                position: 'relative',
                                backgroundColor: 'rgba(255, 255, 255, 0.0)',
                            }}
                            activeStep={activeStep}
                            nextButton={
                                <Button
                                    onClick={handleNext}
                                    disabled={activeStep === props.petPicturesList.length - 1}
                                >
                                    <FontAwesomeIcon icon={faCircleArrowRight} fontSize={"2rem"}/>
                                </Button>
                            }
                            backButton={
                                <Button onClick={handleBack} disabled={activeStep === 0}>
                                    <FontAwesomeIcon icon={faCircleArrowLeft} fontSize={"2rem"}/>
                                </Button>
                            }
                            sx={{
                                '.MuiMobileStepper-dot': {
                                    backgroundColor: 'white',
                                    margin: '0 4px',
                                    width: '13px',
                                    height: '13px',
                                },
                                '.MuiMobileStepper-dotActive': {
                                    backgroundColor: "#404EED",
                                    margin: '0 4px',
                                    width: '13px',
                                    height: '13px',
                                },
                                justifyContent: 'center',
                            }}
                        />
                    </div>
                ) : (
                    <Skeleton height="30rem"/>
                )
            }
        </div>
    )
}


export default PetPicturesCarousselMicrochip;
