import {Box, Button, Card, CardContent, Paper, Typography} from '@mui/material';
import React, {useEffect, useState} from 'react';
import {useTranslation} from 'react-i18next';
import {LostPet} from "./LostPetList";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faCat, faDog} from "@fortawesome/free-solid-svg-icons";

interface LostPetCardState {
    pet: LostPet;
}

const LostPetCard: React.FunctionComponent<LostPetCardState> = (props) => {
    const {t} = useTranslation();
    let [petAge, setPetAge] = useState<number>(0);
    let [petAgeText, setPetAgeText] = useState<string>('');

    useEffect(() => {
        calculateAgeInfo();
    });

    const goToGoogleFormLink = () => {
        const formId = '1FAIpQLSdV43ovxc_gVBkwo88h2R66DaLT_HBy8mLeugMxVemF4iaaBQ';
        const petInformation = `${props.pet.name}\n${t('species')}: ${props.pet.species === "Cat" ? t('cat') : t('dog')}\n${t('age')}: ${petAge} ${petAgeText}\n${t('sex')}: ${props.pet.sex}\n${t("description_bio")}: ${props.pet.notes}`;

        // Construct the pre-filled link
        const preFilledUrl = `https://docs.google.com/forms/d/e/${formId}/viewform?usp=pp_url&entry.1159402452=${encodeURIComponent(petInformation)}`;

        // Open the link in a new tab
        window.open(preFilledUrl, '_blank');
    };

    const calculateAgeInfo = () => {
        let timeDiff = Math.abs(Date.now() - props.pet.dob.getTime());
        let ageInDays = Math.floor(timeDiff / (1000 * 3600 * 24));

        let months = Math.floor(ageInDays / 30);
        let years = Math.floor(ageInDays / 365);

        if (years > 0) {
            setPetAge(years);
            setPetAgeText(t('years_old'));
        } else if (months > 0) {
            setPetAge(months);
            setPetAgeText(t('months_old'));
        } else {
            setPetAge(ageInDays);
            setPetAgeText(t('days_old'));
        }
    };

    return (
        <Paper elevation={5} style={{
            marginRight: '1rem',
            marginLeft: '1rem',
            borderRadius: '20px',
            position: 'relative',
        }}>
            <Card>
                <CardContent style={{paddingTop: '0rem'}}>
                    {props.pet.mainPetPictureDownloadUrl ? (
                        <Box
                            component="img"
                            sx={{
                                height: 'auto',
                                maxHeight: '250px',
                                maxWidth: '250px',
                                overflow: 'hidden',
                                marginTop: '2rem',
                            }}
                            src={props.pet.mainPetPictureDownloadUrl}
                            alt="Pet picture"
                        />
                    ) : (
                        <>
                            {props.pet.species === "Cat" ? (
                                <FontAwesomeIcon icon={faCat} style={{marginTop: '2rem', marginBottom: '150px'}} fontSize={"100px"}/> // Use a different icon for cats
                            ) : (
                                <FontAwesomeIcon icon={faDog} style={{marginTop: '2rem', marginBottom: '150px'}} fontSize={"100px"}/> // Example icon for other pets
                            )}
                        </>
                    )}
                    <Typography sx={{textAlign: 'left', fontSize: '1.8rem', fontWeight: 'bold'}}>
                        {props.pet.name}
                    </Typography>
                    <Typography sx={{textAlign: 'left', fontSize: '1.3rem'}}>
                        <Typography component="span" sx={{fontWeight: 'bold', fontSize: '1.5rem'}}>
                            {t('species')}:
                        </Typography>{' '}
                        {props.pet.species === "Cat" ? t('cat') : t('dog')}
                    </Typography>
                    <Typography sx={{textAlign: 'left', fontSize: '1.3rem'}}>
                        <Typography component="span" sx={{fontWeight: 'bold', fontSize: '1.5rem'}}>
                            {t('age')}:
                        </Typography>{' '}
                        {petAge} {petAgeText}
                    </Typography>
                    <Typography sx={{textAlign: 'left', fontSize: '1.3rem'}}>
                        <Typography component="span" sx={{fontWeight: 'bold', fontSize: '1.5rem'}}>
                            {t('sex')}:
                        </Typography>{' '}
                        {props.pet.sex}
                    </Typography>
                    <Typography style={{textAlign: 'left'}} sx={{fontSize: '1.5rem', fontWeight: 'bold'}}
                                gutterBottom>
                        {t("description_bio")}
                    </Typography>
                    <Typography style={{textAlign: 'left'}} sx={{fontSize: '1.3rem', color: "#8B8B8B"}}
                                gutterBottom>
                        {props.pet.notes}
                    </Typography>
                    <Button
                        variant="contained"
                        onClick={goToGoogleFormLink}
                        style={{
                            marginLeft: "1rem",
                            color: "#404EED",
                            padding: "1rem",
                            borderRadius: 10,
                            border: "2px solid #404EED",
                        }}>
                        <Typography color={"#FFFFFF"}>{t("found_this_pet")}</Typography>
                    </Button>
                </CardContent>
            </Card>
        </Paper>
    )
}


export default LostPetCard;
