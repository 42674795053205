import {faEnvelope, faPhone} from '@fortawesome/free-solid-svg-icons';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {Avatar, Card, CardContent, CardHeader, Grid, IconButton, Paper, Snackbar, Typography} from '@mui/material';
import axios from 'axios';
import React, {useState} from 'react';
import ReCAPTCHA from 'react-google-recaptcha';
import {config} from '../Constant';
import {useTranslation} from 'react-i18next';

const API_URL = config.url.API_URL;

interface ContactInfoState {
    contactList: any[];
}

const ContactInfoCard: React.FunctionComponent<ContactInfoState> = (props) => {
    const {t} = useTranslation();
    let [isCaptchaHidden, setIsCaptchaHidden] = useState<boolean>(true);
    let [isCaptchaComplete, setIsCaptchaComplete] = useState<boolean>(false);
    let [captchaToken, setCaptchaToken] = useState<string>('');

    let [contactButtonClickedName, setContactButtonClickedName] = useState<string>('');
    let [selectedContactIndex, setSelectedContactIndex] = useState<number>(0);

    const [open, setOpen] = useState<boolean>(false);
    const [snackbarText, setSnackbarText] = useState<string>("");

    const handleClose = () => {
        setOpen(false);
    };

    const handleOpen = () => {
        setOpen(true);
    };

    const fetchAccountEmail = (captchaToken: string) => {
        axios.get(`${API_URL}accountEmail/${props.contactList[selectedContactIndex][0].id}`, {
            headers: {authorization: 'Bearer ' + captchaToken},
        })
            .then(function (response) {
                if (response.data.accountInfo.email !== null && response.data.accountInfo.email !== '') {
                    window.location.href = `mailto:${response.data.accountInfo.email}`;
                } else {
                    setSnackbarText(t("user_no_email_message"));
                    handleOpen();
                }
            })
            .catch(function (error) {
                console.log(error);
            })
    };

    const fetchAccountPhoneNumber = (captchaToken: string) => {
        axios.get(`${API_URL}petOwnerPhoneNumber/${props.contactList[selectedContactIndex][0].id}`, {
            headers: {authorization: 'Bearer ' + captchaToken},
        })
            .then(function (response) {
                if (response.data.accountInfo.phone_number !== null && response.data.accountInfo.phone_number !== '') {
                    window.location.href = `tel:${response.data.accountInfo.phone_number}`;
                } else {
                    setSnackbarText(t("user_no_phone_message"));
                    handleOpen();
                }
            })
            .catch(function (error) {
                console.log(error);
            })
    };

    const handlePhoneIconClick = (index: number) => {
        if (isCaptchaComplete) {
            setContactButtonClickedName('phone');
            fetchAccountPhoneNumber(captchaToken);
        } else {
            setIsCaptchaHidden(false);
            setSelectedContactIndex(index);
            setContactButtonClickedName('phone');
        }
    }

    const handleEmailIconClick = (index: number) => {
        if (isCaptchaComplete) {
            setContactButtonClickedName('email');
            fetchAccountEmail(captchaToken);
        } else {
            setIsCaptchaHidden(false);
            setSelectedContactIndex(index);
            setContactButtonClickedName('email');
        }
    }

    const handleCaptchaExpired = () => {
        setIsCaptchaHidden(false);
        setIsCaptchaComplete(false);
    }

    const handleCaptchaComplete = async (token: string | null) => {
        if (token !== null) {
            setIsCaptchaHidden(true);
            setIsCaptchaComplete(true);
            setCaptchaToken(token);

            //Check which button was clicked
            switch (contactButtonClickedName) {
                case 'phone': {
                    fetchAccountPhoneNumber(token);
                    break;
                }
                case 'email': {
                    fetchAccountEmail(token);
                    break;
                }
                case 'address': {
                    break;
                }
                default: {
                    console.log("Other")
                    break;
                }
            }
        }


    }

    return (
        <Paper elevation={5} style={{
            margin: '1rem',
            borderRadius: '20px',
        }}>
            <Card
                variant="outlined"
                style={{
                    borderRadius: '20px',
                }}>
                <CardHeader title={t("contacts")} style={{textAlign: 'left'}}/>
                <CardContent>
                    <ReCAPTCHA
                        hidden={isCaptchaHidden}
                        sitekey="6LcUj8MmAAAAAJ_g9G3lhtgGCa2P1b22UnjkXuUp"
                        onChange={(token) => handleCaptchaComplete(token)}
                        onExpired={handleCaptchaExpired}
                    />
                    {props.contactList.map((contact, index) => {
                        return (
                            <Grid key={index} justifyContent="center" alignItems="center" container
                                  spacing={{xs: 2, md: 3}} columns={{xs: 24, sm: 24, md: 24}}>
                                <Grid item={true} xs={4} sm={4} md={4}>
                                    <Avatar alt="Profile Picture" src={contact[2].profile_picture_url}/>
                                </Grid>
                                <Grid style={{textAlign: 'left'}} item={true} xs={10} sm={10} md={10}>
                                    <Typography variant='h6' fontWeight={"bold"}>{contact[1].first_name}</Typography>
                                </Grid>
                                <Grid item={true} xs={3} sm={3} md={3}>
                                    <IconButton onClick={() => handlePhoneIconClick(index)}><FontAwesomeIcon
                                        icon={faPhone} size="xl"/></IconButton>
                                </Grid>
                                <Grid item={true} xs={1} sm={1} md={1}>
                                </Grid>
                                <Grid item={true} xs={3} sm={3} md={3}>
                                    <IconButton onClick={() => handleEmailIconClick(index)}><FontAwesomeIcon
                                        icon={faEnvelope} size="xl"/></IconButton>
                                </Grid>
                            </Grid>
                        );
                    })}
                </CardContent>
            </Card>
            <Snackbar
                open={open}
                autoHideDuration={5000}
                onClose={handleClose}
                message={snackbarText}
            />
        </Paper>


    )
}


export default ContactInfoCard;
